<!--
 * @FilePath: \zsw_new_edition\src\views\student\index.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2022-02-08 09:52:25
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-02-25 16:25:03
-->
<template>
  <div
    class="signBox"
    v-if="!isLogin || userInfo.is_student == 2 || userInfo.is_student == -1"
  >
    <div class="top">
      <img src="../../assets/image/student/bck.png" alt="" class="bckImg" />
      <div class="topContent">
        <p class="topContentP">Student Certification Activities</p>
        <p class="topContentP">学生认证活动</p>
        <p class="topContentP">助力“所有设计学子”更好的学习设计！</p>
        <p class="topContentP">知设网推出“2023年学生认证版”</p>
        <div class="topContentBox">
          <div class="contentBox contentBox1">
            <div>
              <img src="../../assets/image/student/img1.png" alt="" />
            </div>
            <p>购买课程VIP享8折</p>
          </div>
          <div class="contentBox contentBox2">
            <div>
              <img src="../../assets/image/student/img2.png" alt="" />
            </div>
            <p>线下集训任意课程享8折</p>
          </div>
          <div class="contentBox contentBox3">
            <div>
              <img src="../../assets/image/student/img3.png" alt="" />
            </div>
            <p>购买素材VIP享8折</p>
          </div>
          <div class="contentBox contentBox4">
            <div>
              <img src="../../assets/image/student/img1.png" alt="" />
            </div>
            <p>专属设计群辅导/服务</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <p class="bottomTitle">请填写以下信息</p>
      <div class="info">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <ul>
            <li>
              <div class="dian"></div>
              <p>学校名称</p>
              <el-form-item prop="schoolName">
                <el-input
                  v-model="ruleForm.schoolName"
                  placeholder="——请输入学校名称——"
                ></el-input>
              </el-form-item>
            </li>
            <li>
              <div class="dian"></div>
              <p>就读专业</p>
              <el-form-item prop="specialty">
                <el-input
                  v-model="ruleForm.specialty"
                  placeholder="——请输入就读专业——"
                ></el-input>
              </el-form-item>
            </li>
            <li>
              <div class="dian"></div>
              <p>在读学历</p>
              <el-form-item prop="education">
                <el-select
                  v-model="ruleForm.education"
                  placeholder="——请选择在读学历——"
                >
                  <el-option label="高中" value="高中"></el-option>
                  <el-option label="大专" value="大专"></el-option>
                  <el-option label="本科" value="本科"></el-option>
                  <el-option label="硕士" value="硕士"></el-option>
                  <el-option label="博士" value="博士"></el-option>
                </el-select>
              </el-form-item>
            </li>
            <li>
              <div class="dian"></div>
              <p>入学时间</p>
              <el-form-item prop="admissionTime">
                <el-date-picker
                  :clearable="false"
                  value-format="yyyy-MM"
                  v-model="ruleForm.admissionTime"
                  type="month"
                  placeholder="请选择入学时间"
                >
                </el-date-picker>
              </el-form-item>
            </li>
            <li>
              <div class="dian"></div>
              <p>预计毕业时间</p>
              <el-form-item prop="graduateTime">
                <el-date-picker
                  :clearable="false"
                  value-format="yyyy-MM"
                  v-model="ruleForm.graduateTime"
                  type="month"
                  placeholder="请选择预计毕业时间"
                >
                </el-date-picker>
              </el-form-item>
            </li>
          </ul>
        </el-form>
      </div>
      <div class="border"></div>
      <p class="bottomTitle">请上传学生证附件</p>
      <p class="bottomTitle1 mt30">
        .
        学生证信息需包含：学校名称、姓名、照片、专业、出生年份、入学年月、毕业年份（或学制、有效时间），并加盖学校印章
      </p>
      <p class="bottomTitle1">. 图片格式要求jpg、jpeg、png、pdf，且文字清晰</p>
      <div class="studentCardBox">
        <div class="cardBox">
          <p><span>*</span>学生证封面</p>
          <div class="photo">
            <img
              src="../../assets/image/student/border1.png"
              alt=""
              class="borderImg1"
            />
            <img
              src="../../assets/image/student/border2.png"
              alt=""
              class="borderImg2"
            />
            <div class="examplePhoto">
              <el-upload
                :show-file-list="false"
                :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
                :on-success="handleAvatarSuccess1"
                accept="image/png,image/jpg,image/jpeg"
              >
                <img
                  src="../../assets/image/student/student1.png"
                  class="examplePhotoImg"
                  v-if="!ruleForm.studentCard1"
                />
                <img
                  v-else
                  :src="ruleForm.studentCard1"
                  alt=""
                  class="photoImg"
                />
              </el-upload>
            </div>
          </div>
        </div>
        <div class="cardBox">
          <p><span>*</span>学生证内页一</p>
          <div class="photo">
            <img
              src="../../assets/image/student/border1.png"
              alt=""
              class="borderImg1"
            />
            <img
              src="../../assets/image/student/border2.png"
              alt=""
              class="borderImg2"
            />
            <div class="examplePhoto">
              <el-upload
                :show-file-list="false"
                :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
                :on-success="handleAvatarSuccess2"
                accept="image/png,image/jpg,image/jpeg"
              >
                <img
                  src="../../assets/image/student/student2.png"
                  class="examplePhotoImg"
                  v-if="!ruleForm.studentCard2"
                />
                <img
                  v-else
                  :src="ruleForm.studentCard2"
                  alt=""
                  class="photoImg"
                />
              </el-upload>
            </div>
          </div>
        </div>
        <div class="cardBox">
          <p>学生证内页二</p>
          <div class="photo">
            <img
              src="../../assets/image/student/border1.png"
              alt=""
              class="borderImg1"
            />
            <img
              src="../../assets/image/student/border2.png"
              alt=""
              class="borderImg2"
            />
            <div class="examplePhoto">
              <el-upload
                :show-file-list="false"
                :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
                :on-success="handleAvatarSuccess3"
                accept="image/png,image/jpg,image/jpeg"
              >
                <img
                  src="../../assets/image/student/student2.png"
                  class="examplePhotoImg"
                  v-if="!ruleForm.studentCard3"
                />
                <img
                  v-else
                  :src="ruleForm.studentCard3"
                  alt=""
                  class="photoImg"
                />
              </el-upload>
            </div>
          </div>
        </div>
      </div>
      <p class="bottomTitle">请上传手持身份证</p>
      <p class="bottomTitle1 mt30">
        . 身份证信息需包含：人像、姓名、出生年月、身份证号
      </p>
      <p class="bottomTitle1">. 图片格式要求jpg、jpeg、png、pdf，且文字清晰</p>
      <div class="studentCardBox">
        <div class="cardBox">
          <p><span>*</span>手持身份证</p>
          <div class="photo">
            <img
              src="../../assets/image/student/border1.png"
              alt=""
              class="borderImg1"
            />
            <img
              src="../../assets/image/student/border2.png"
              alt=""
              class="borderImg2"
            />
            <div class="examplePhoto">
              <el-upload
                :show-file-list="false"
                :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
                :on-success="handleAvatarSuccess4"
                accept="image/png,image/jpg,image/jpeg"
              >
                <img
                  src="../../assets/image/student/add.png"
                  class="examplePhotoImg1"
                  v-if="!ruleForm.idcard"
                />
                <img v-else :src="ruleForm.idcard" alt="" class="photoImg" />
              </el-upload>
            </div>
          </div>
        </div>
        <div class="cardBox">
          <p><span>示例</span></p>
          <div class="photo">
            <img
              src="../../assets/image/student/border1.png"
              alt=""
              class="borderImg1"
            />
            <img
              src="../../assets/image/student/border2.png"
              alt=""
              class="borderImg2"
            />
            <div class="examplePhoto">
              <img
                src="../../assets/image/student/idcard.png"
                class="photoImg"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="userInfo">
        <div
          class="submit"
          @click="submitInfo('ruleForm')"
          v-if="userInfo.is_student == 2 || userInfo.is_student == -1"
        >
          <p>提交认证</p>
        </div>
        <div class="submit submit1" v-if="userInfo.is_student == 1">
          <p>已完成认证</p>
        </div>
        <div class="submit submit1" v-if="userInfo.is_student == 0">
          <p>认证审核中</p>
        </div>
      </div>
    </div>
  </div>
  <div class="center" v-else>
    <div v-if="userInfo.is_student == 1" class="success">
      <img src="../../assets/image/icon/addBankSucceed.png" alt="" />
      <p>您已完成学生认证</p>
      <p>
        去VIP页面看看吧，超值折扣！
        <router-link :to="{ path: '/vip/material' }"
          ><span>立即跳转</span></router-link
        >
      </p>
    </div>
    <div v-if="userInfo.is_student == 0" class="review">
      <img src="../../assets/image/icon/review.png" alt="" />
      <p>学生认证中...</p>
      <p>请耐心等待，审核结果会在消息中心告知已读</p>
    </div>
  </div>
</template>

<script>
import { getToken } from "../../utils/auth";
import { studentCertification } from "../../Api";
import { mapGetters } from "vuex";
export default {
  name: "SignIndex",
  data() {
    return {
      token: getToken(),
      ruleForm: {
        studentCard1: "",
        studentCard2: "",
        studentCard3: "",
        idcard: "",
        graduateTime: "",
        admissionTime: "",
        education: "",
        schoolName: "",
        specialty: "",
      },
      rules: {
        admissionTime: [
          { required: true, message: "请选择入学时间", trigger: "change" },
        ],
        graduateTime: [
          { required: true, message: "请选择预计毕业时间", trigger: "change" },
        ],
        schoolName: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        education: [
          { required: true, message: "请选择在读学历", trigger: "change" },
        ],
        specialty: [
          { required: true, message: "请输入就读专业", trigger: "blur" },
        ],
        studentCard1: [
          { required: true, message: "请上传学生证封面", trigger: "change" },
        ],
        studentCard2: [
          { required: true, message: "请上传学生证内页", trigger: "change" },
        ],
        idcard: [
          { required: true, message: "请上传手持身份证", trigger: "change" },
        ],
      },
    };
  },
  created() {
    if (!this.isLogin) this.$login();
  },
  methods: {
    //上传成功
    handleAvatarSuccess1(res) {
      if (res.data.message == "uploadSuccess") {
        this.ruleForm.studentCard1 = res.data.filePath;
      }
    },
    //上传成功
    handleAvatarSuccess2(res) {
      if (res.data.message == "uploadSuccess") {
        this.ruleForm.studentCard2 = res.data.filePath;
      }
    },
    //上传成功
    handleAvatarSuccess3(res) {
      if (res.data.message == "uploadSuccess") {
        this.ruleForm.studentCard3 = res.data.filePath;
      }
    },
    //上传成功
    handleAvatarSuccess4(res) {
      if (res.data.message == "uploadSuccess") {
        this.ruleForm.idcard = res.data.filePath;
      }
    },
    submitInfo(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.ruleForm.studentCard1) {
            this.$message.error("请上传学生证封面");
            return;
          }
          if (!this.ruleForm.studentCard2) {
            this.$message.error("请上传学生证内页一");
            return;
          }
          if (!this.ruleForm.idcard) {
            this.$message.error("请上传手持身份证");
            return;
          }
          const data = {
            school: this.ruleForm.schoolName,
            profession: this.ruleForm.specialty,
            education: this.ruleForm.education,
            start_time: this.ruleForm.admissionTime,
            end_time: this.ruleForm.graduateTime,
            id_card: this.ruleForm.idcard,
            degree:
              this.ruleForm.studentCard1 +
              "," +
              this.ruleForm.studentCard2 +
              "," +
              this.ruleForm.studentCard3,
            token: this.token,
          };
          studentCertification(data).then((res) => {
            if (res.data.msg == "success") {
              this.$message.success("提交成功，请耐心等待审核");
              window.location.reload();
            }
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters(["isLogin", "userInfo"]),
  },
};
</script>

<style scoped>
.top {
  position: relative;
  height: 6.05rem;
  margin: 0.15rem 0.32rem 0.08rem 2.38rem;
}
.top .bckImg {
  position: absolute;
  height: 6.05rem;
  z-index: -1;
}
.topContent {
  position: absolute;
  top: 0.45rem;
  left: 0.42rem;
}
.topContent .topContentP:nth-child(1) {
  font-size: 0.52rem;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #040404;
  line-height: 0.73rem;
}
.topContent .topContentP:nth-child(2) {
  font-size: 0.4rem;
  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
  font-weight: bold;
  color: #040404;
  line-height: 0.56rem;
  margin-bottom: 0.38rem;
}
.topContent .topContentP:nth-child(3) {
  font-size: 0.18rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #666666;
  line-height: 0.25rem;
}
.topContent .topContentP:nth-child(4) {
  font-size: 0.18rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #666666;
  line-height: 0.25rem;
}

.topContentBox {
  display: flex;
}
.contentBox {
  width: 1.4rem;
  height: 1.6rem;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 0.6rem;
  margin-right: 0.1rem;
}

.contentBox1 {
  background: #ffebe5;
}

.contentBox2 {
  background: #dfeaff;
}

.contentBox3 {
  background: #fff0de;
}

.contentBox4 {
  background: #e8f8e4;
}

.contentBox div {
  margin: 0.2rem 0.4rem 0.16rem;
  width: 0.6rem;
  height: 0.6rem;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentBox div img {
  width: 0.3rem;
  height: 0.3rem;
}
.contentBox p {
  width: 0.89rem;
  font-size: 0.16rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  line-height: 0.22rem;
  text-align: center;
  margin-left: 0.26rem;
}

.contentBox1 p {
  color: #ff5e43;
}

.contentBox2 p {
  color: #6ea0fd;
  width: 0.97rem;
}
.contentBox3 p {
  color: #ff8b00;
}
.contentBox4 p {
  color: #53a59a;
  width: 0.81rem;
}

.bottom {
  margin: 0.08rem 0 0 2.8rem;
}
.bottomTitle {
  font-size: 0.24rem;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #040404;
  line-height: 0.34rem;
}

.info li {
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  position: relative;
}
.info li p {
  position: absolute;
  left: 0.26rem;
  font-size: 0.18rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #040404;
  line-height: 0.25rem;
}
.dian {
  width: 0.1rem;
  height: 0.1rem;
  background: #ff5e43;
  opacity: 1;
  border-radius: 50%;
}
ul {
  margin-top: 0.6rem;
}
::v-deep .el-form-item {
  margin-bottom: 0;
  margin-left: 1.74rem;
}

::v-deep .el-form-item__error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

::v-deep .el-select {
  display: inline-block;
  position: relative;
  width: 4rem;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  /* background: rgba(223, 234, 255, 0.4000000059604645); */
  background-image: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 4rem;
  height: 0.42rem;
  text-align: center;
}

.border {
  margin: 0.12rem 0 0.51rem;
  width: 13.6rem;
  opacity: 1;
  border: 1px dashed #ffebe5;
}
.bottomTitle1 {
  font-size: 0.14rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 0.2rem;
}
.studentCardBox {
  margin-top: 0.4rem;
  margin-bottom: 0.6rem;
  display: flex;
}
.cardBox {
  width: 3.45rem;
  height: 2.5rem;
  margin-right: 0.2rem;
}
.cardBox p {
  text-align: center;
  font-size: 0.16rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 0.22rem;
  margin-bottom: 0.2rem;
}
.cardBox p span {
  color: #ff5e43;
  margin-right: 0.1rem;
}
.photo {
  width: 3.45rem;
  height: 2.17rem;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  position: relative;
}

.photo:hover .borderImg1 {
  visibility: hidden;
}
.photo:hover .borderImg2 {
  visibility: visible;
}
.photo .borderImg1 {
  top: 0;
  width: 3.45rem;
  position: absolute;
  z-index: -1;
  visibility: visible;
}
.photo .borderImg2 {
  top: 0;
  width: 3.45rem;
  position: absolute;
  z-index: -1;
  visibility: hidden;
}
.examplePhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.45rem;
  height: 2.175rem;
}

.examplePhotoImg {
  width: 1.9rem;
  height: 1.42rem;
}
.examplePhotoImg1 {
  width: 0.44rem;
  height: 0.44rem;
}
.photoImg {
  width: 3.3rem;
  height: 2rem;
  border-radius: 2%;
}
.submit {
  margin: 0.8rem 0 0.8rem;
  width: 1.84rem;
  height: 0.48rem;
  background: #ff5e43;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit1 {
  background: #d9d9d9 !important;
}
.submit p {
  font-size: 0.16rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.22rem;
}
.center {
  margin: 3.02rem auto 3.7rem;
  display: flex;
  justify-content: center;
}
.center .success img {
  margin-left: 0.97rem;
  width: 2.26rem;
  height: 2.24rem;
  margin-bottom: 00.4rem;
}
.center .review img {
  width: 2.26rem;
  height: 2.24rem;
  margin-bottom: 00.4rem;
}
.center div p {
  text-align: center;
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #040404;
  opacity: 1;
}
.center div span {
  color: #f46600;
}
</style>